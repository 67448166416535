export const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../components/pages/Dashboard.vue'),
  },
  {
    path: '/profile',
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('../components/pages/Profile.vue'),
      }
    ]
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../components/pages/Support.vue'),
  },

  {
    path: '/survey/:slug',
    name: "survey",
    children: [
      {
        path: ':screen_id?',
        name: 'survey-screen',
        component: () => import('../components/pages/Surveys/Show.vue'),
      },
      {
        path: 'the-end',
        name: 'survey-conclusion',
        component: () => import('../components/pages/Surveys/Conclusion.vue'),
      },

    ]
  },
  // {
  //   path: '/mighty_moments',
  //   children: [
  //     {
  //       path: '',
  //       name: "mighty-moments-index",
  //       component: () => import('../components/pages/MightyMoments/Index.vue')
  //     }
  //   ]
  // },
  {
    path: '/requests',
    children: [
      {
        path: '',
        name: 'requests-index',
        component: () => import('../components/pages/Requests/Index.vue'),
      },
      {
        path: 'feedback/thank-you',
        name: 'requests-feedback-thank-you',
        component: () => import('../components/pages/Requests/Index.vue'),
      },
      {
        path: 'new',
        name: 'requests-new',
        component: () => import('../components/pages/Requests/New.vue'),
        children: [
          {
            path: 'recommended',
            name: 'requests-recommended-new',
            component: () => import('../components/pages/Requests/New.vue'),
          }
        ]
      },
    ]
  },
  {
    path: '/recommendations',
    children: [
      {
        path: '',
        name: 'recommendations-index',
        component: () => import('../components/pages/Recommendations/Index.vue'),
      }
    ]
  },
  {
    path: '/mfa',
    children: [
      {
        path: 'add-number',
        name: 'multifactor-authentication-add-number',
        component: () => import('../components/pages/MultifactorAuthentication/Number.vue'),
      },
      {
        path: 'verify',
        name: 'multifactor-authentication-verify',
        component: () => import('../components/pages/MultifactorAuthentication/Verify.vue'),
      }
    ]
  },
  {
    path: '/products/:slug',
    name: 'product-categories-show',
    component: () => import('../components/pages/ProductCategories/Show.vue'),
  },
  {
    path: '/product-bundles/:id/quotes/:quote_id:',
    name: 'product-bundle-show',
    component: () => import('../components/pages/ProductBundles/Show.vue'),
  },
  {
    path: '/application/:id',
    name: 'applications-show',
    component: () => import('../components/pages/Applications/Show.vue'),
  },
  {
    path: '/quotes/:id',
    name: 'quotes-show',
    component: () => import('../components/pages/ProductCategories/Show.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../components/pages/NotFound.vue'),
  }
]