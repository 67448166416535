<script setup>
import { ref } from 'vue'
import devisePaths from '../../../routes/Devise/SessionsApi';
import CompanyButtonLogo from '../atoms/images/CompanyButtonLogo.vue'
import { useUserStore } from '../../../stores/user'
import * as Sentry from "@sentry/vue";

const user = useUserStore();
const axios = require('axios').default;
const props = defineProps({
  companyName: {
    type: String,
    required: false,
    default: null
  },
  fullLogoFallback: {
    type: Boolean,
    required: false,
    default: false
  },
  moveToPreviousScreen: {
    type: Function,
    required: false
  }
})

const isOpen = ref(false);

const companyNameDisplay = () => {
  if (props.companyName) {
    return props.companyName
  } else if (user.company) {
    return user.company.name
  } else {
    return 'Overalls'
  }
}

window.onscroll = function() {
  if (isOpen.value) {
    window.scrollTo(0, 0)
  }
}

const logout = async () => {
  await devisePaths.destroy().then(() => {
    location.reload();
  }).catch((error) => {
    location.reload();
    Sentry.withScope((scope) => {
      Sentry.setUser({id: user.id})
      Sentry.setTag('Session', 'destroy')
      Sentry.captureException(error)
    });
  });
}

const toggleNav= () => {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <nav class='drop-shadow-lg bg-white'>
    <div class="flex flex-row pt-3.5 pb-3.5 pr-5 md:pt-5 md:pb-5 pl-5 bg-none">
      <template v-if="!!companyName || !fullLogoFallback">
        <div class="ml-auto border-r border-gray-border vertically-centered-content pr-3.5">
          <router-link :to="{ name: 'dashboard' }">
            <CompanyButtonLogo class="h-14" />
          </router-link>
        </div>
        <div class="mr-auto vertically-centered-content pl-3.5">
          <h1 class="font-subhead text-2xl leading-loose uppercase">{{ companyNameDisplay() }}</h1>
        </div>
      </template>
      <template v-else>
        <div class="mx-auto vertically-centered-content">
          <router-link :to="{ name: 'dashboard' }">
            <img :src="require('/app/javascript/images/OverallsLogo.svg')" alt="Overalls logo" role="presentation" />
          </router-link>
        </div>
      </template>
      <div class="vertically-centered-content">
        <button class="z-50 text-overalls-green w-10 h-10 relative focus:outline-none" @click.native="toggleNav">
          <span class="sr-only">Open main menu</span>
          <div class="block w-8 absolute left-4 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-49">
            <span aria-hidden="true" class="block absolute h-1 w-5 md:w-7 bg-current transform transition duration-500 ease-in-out" :class="{'rotate-45': isOpen,'-translate-y-1.5': !isOpen }"></span>
            <span aria-hidden="true" class="block absolute h-1 w-5 md:w-7 bg-current transform transition duration-500 ease-in-out" :class="{'opacity-0': isOpen } "></span>
            <span aria-hidden="true" class="block absolute h-1 w-5 md:w-7 bg-current transform transition duration-500 ease-in-out" :class="{'-rotate-45': isOpen, 'translate-y-1.5': !isOpen}"></span>
          </div>
        </button>
      </div>
    </div>

    <!-- right section -->

    <Transition name="slide-fade">
      <div v-if="isOpen" class="top-0 right-0 max-w-xs w-2/3 md:w-2/5 flex-col justify-center text-left z-40 h-screen p-8 fixed bg-white" >
        <div class="flex mb-11">
          <router-link :to="{ name: 'dashboard' }">
            <CompanyButtonLogo class="h-8 md:h-14" />
          </router-link>
        </div>
        <div v-if='Object.keys(user.currentUser).length !== 0 && user.mfaAuthenticated'>
          <ul>
            <li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'dashboard' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                Home
              </router-link>
            </li>
            <li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'requests-new' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                LifeConcierge
              </router-link>
            </li>
            <li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'requests-index' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                My Requests
              </router-link>
            </li>
            <!-- li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'mighty-moments-index' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                Mighty Moments
              </router-link>
            </li -->
            <li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'recommendations-index' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                Partners
              </router-link>
            </li>
            <li v-if="user.company.is_admin" class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'profile' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                Profile
              </router-link>
            </li>
            <li class="text-xl text-overalls-green mb-4">
              <router-link :to="{ name: 'support' }" class="cursor-pointer hover:underline underline-offset-auto" @click="toggleNav">
                Support
              </router-link>
            </li>
            <li class="text-xl text-overalls-green mb-4">
              <a @click='logout' class="cursor-pointer hover:underline underline-offset-auto">Sign Out</a><br />
            </li>
          </ul>
        </div>
        <div v-else>
          <ul>
            <li>
              <a href='/users/sign_in'>Sign In</a><br />
            </li>
          </ul>
        </div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="isOpen" class="z-45 top-0 left-0 opacity-50 absolute bg-black w-screen h-screen overflow-hidden"></div>
    </Transition>
  </nav>
</template>

<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-to, .fade-leave-from {
  opacity: 0.25;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
